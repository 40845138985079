

export const fireBaseConfig = {
  apiKey: "AIzaSyABSF7jurOoECuicPcyqZrnaZs1TlFUeUI",
  authDomain: "fermentation-control-a8c8f.firebaseapp.com",
  databaseURL: "https://fermentation-control-a8c8f-default-rtdb.firebaseio.com",
  projectId: "fermentation-control-a8c8f",
  storageBucket: "fermentation-control-a8c8f.appspot.com",
  messagingSenderId: "309302841539",
  appId: "1:309302841539:web:fa84afc63d0065af529276"
};


// Initialize Firebase
// const app = initializeApp(firebaseConfig);
/*
export const fireBaseConfig = {
const firebaseConfig = {
  apiKey: "AIzaSyABSF7jurOoECuicPcyqZrnaZs1TlFUeUI",
  authDomain: "fermentation-control-a8c8f.firebaseapp.com",
  databaseURL: "https://fermentation-control-a8c8f-default-rtdb.firebaseio.com",
  projectId: "fermentation-control-a8c8f",
  storageBucket: "fermentation-control-a8c8f.appspot.com",
  messagingSenderId: "309302841539",
  appId: "1:309302841539:web:4118255f4942cdce529276"
}; */
